import React from 'react';
import cn from 'classnames';
import { Core, Form, Layout, Localization } from 'connex-cds';
import styled from 'styled-components';
import style from '../style';

const { Column, Row, Container } = Layout;

const Styled = styled.div`
  ${style}
`;

export const MaterialComplianceDesktop = ({
  loadingMaterialCompliance,
  airUom,
  airShowIsComplianceCheckbox,
  consistenceUom,
  consistenceShowIsComplianceCheckbox,
  airTempUom,
  concreteTempUom,
  cylinderUom,
  consistenceDisabled,
  airDisabled,
  materialComplianceNotFound,
}) => {
  const {
    Components: {
      ConsistenceValue,
      ConsistenceIsCompliant,
      AirContentValue,
      AirContentIsCompliant,
      DateTest,
      AirTempValue,
      ConcreteTempValue,
      CylinderNumber,
      AdditionalInfo,
    },
  } = Form.useFormContext();

  return (
    <Styled className={cn('material-compliance')}>
      <Container>
        <Column>
          <Core.Spinner spin={loadingMaterialCompliance}>
            {consistenceUom && (
              <>
                <Row>
                  <Column>
                    <ConsistenceValue uomCode={consistenceUom} />
                  </Column>
                </Row>
                {consistenceShowIsComplianceCheckbox && (
                  <Row>
                    <Column>
                      <ConsistenceIsCompliant disabled={consistenceDisabled} />
                    </Column>
                  </Row>
                )}
              </>
            )}
            {airUom && (
              <>
                <Row>
                  <Column>
                    <AirContentValue uomCode={airUom} />
                  </Column>
                </Row>
                {airShowIsComplianceCheckbox && (
                  <Row>
                    <Column>
                      <AirContentIsCompliant disabled={airDisabled} />
                    </Column>
                  </Row>
                )}
              </>
            )}
            {airTempUom && (
              <Row>
                <Column>
                  <AirTempValue uomCode={airTempUom} />
                </Column>
              </Row>
            )}
            {concreteTempUom && (
              <Row>
                <Column>
                  <ConcreteTempValue uomCode={concreteTempUom} />
                </Column>
              </Row>
            )}
            {cylinderUom && (
              <Row>
                <Column>
                  <CylinderNumber uomCode={cylinderUom} />
                </Column>
              </Row>
            )}
            {[airUom, consistenceUom, airTempUom, concreteTempUom, cylinderUom].some(Boolean) && (
              <>
                <Row>
                  <Column>
                    <AdditionalInfo rows={3} validationText={false} />
                  </Column>
                </Row>

                <Row>
                  <Column>
                    <DateTest />
                  </Column>
                </Row>
              </>
            )}

            {materialComplianceNotFound && (
              <Row>
                <Column className="material-compliance-not-found">
                  <Localization.Translate
                    stringId="materialCompliance_notFound"
                    data-testid="materialCompliance_notFound"
                  />
                </Column>
              </Row>
            )}
          </Core.Spinner>
        </Column>
      </Container>
    </Styled>
  );
};
