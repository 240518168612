import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization, Form, Theme } from 'connex-cds';
import { SignaturesSection } from './SignaturesSection';
import style, { StyledBodyMobile, StyledFooter, StyledHeader, StyledTable, StyledDiv } from './Style';

const { Translate } = Localization;

const Styled = styled.section`
  ${style}
`;

const testId = 'addedItemsGrid';

export const AddedItemsMobile = ({ productCompliance }) => {
  const { values } = Form.useFormContext();
  const { themeName: theme } = Theme.useThemeContext();
  const translateMessage = Localization.useTranslateMessage();
  const { airContentValue, airTempValue, concreteTempValue, cylinderNumber, consistenceValue } = productCompliance;
  const [result, setResult] = useState({});

  const totalActualWater = useMemo(() => {
    if (values?.waterAddedEvents) {
      const newResult = values.waterAddedEvents.reduce((acc, event) => {
        const waterLocationValue = event.waterLocation?.id;

        if (acc[waterLocationValue]) {
          acc[waterLocationValue] = {
            quantity: acc[waterLocationValue].quantity + event.quantity?.value,
            uomCode: event.quantity.uomCode,
          };
        } else {
          acc[waterLocationValue] = {
            quantity: event.quantity.value,
            uomCode: event.quantity.uomCode,
          };
        }

        return acc;
      }, {});

      setResult(newResult);

      const total = Object.values(newResult).reduce(
        (acc, item) => {
          acc.quantity += item.quantity;
          acc.uomCode = item.uomCode;
          return acc;
        },
        { quantity: 0, uomCode: '' }
      );

      return total;
    }

    return { quantity: 0, uomCode: '' };
  }, [values?.waterAddedEvents]);
  return (
    <Styled data-testid={testId} className={cn('added-items-section')} theme={theme}>
      <StyledTable className={cn('added-items-table')}>
        <StyledHeader>
          <div className={cn('added-items-row')} data-testid={`${testId}-first-header-row`}>
            <Translate stringId="addedItems" data-testid="added-items-label" />
          </div>
        </StyledHeader>
        <StyledBodyMobile>
          <StyledDiv>
            {result['at-home'] ? (
              <>
                <span>
                  <Translate stringId="waterAtPlant" />
                </span>
                {translateMessage(result['at-home'].uomCode, { value: result['at-home'].quantity })}
              </>
            ) : (
              translateMessage('waterAtPlant')
            )}
          </StyledDiv>
          <StyledDiv>
            {result['at-job'] ? (
              <>
                <span>
                  <Translate stringId="waterAtJobsite" />
                </span>
                {translateMessage(result['at-job'].uomCode, { value: result['at-job'].quantity })}
              </>
            ) : (
              translateMessage('waterAtJobsite')
            )}
          </StyledDiv>
          <StyledDiv>
            {airContentValue?.value ? (
              <>
                <span>
                  <Translate stringId="airContentValue" />
                </span>
                {translateMessage(airContentValue?.uomCode, { value: airContentValue.value })}
              </>
            ) : (
              translateMessage('airContentValue')
            )}
          </StyledDiv>
          <StyledDiv>
            {airTempValue?.value ? (
              <>
                <span>
                  <Translate stringId="airTempValue" />
                </span>
                {translateMessage(airTempValue?.uomCode, { value: airTempValue.value })}
              </>
            ) : (
              translateMessage('airTempValue')
            )}
          </StyledDiv>
          <StyledDiv>
            {concreteTempValue?.value ? (
              <>
                <span>
                  <Translate stringId="concreteTempValue" />
                </span>
                {translateMessage(concreteTempValue?.uomCode, { value: concreteTempValue.value })}
              </>
            ) : (
              translateMessage('concreteTempValue')
            )}
          </StyledDiv>
          <StyledDiv>
            {consistenceValue?.value ? (
              <>
                <span>
                  <Translate stringId="consistenceValue" />
                </span>
                {translateMessage(consistenceValue?.uomCode, { value: consistenceValue.value })}
              </>
            ) : (
              translateMessage('consistenceValue')
            )}
          </StyledDiv>
          <StyledDiv>
            {cylinderNumber ? (
              <>
                <span>
                  <Translate stringId="cylinderNumber" />
                </span>
                {translateMessage(cylinderNumber?.uomCode, { value: cylinderNumber.value })}
              </>
            ) : (
              translateMessage('cylinderNumber')
            )}
          </StyledDiv>
          <StyledDiv>
            {Object.keys(result).length > 0 ? (
              <>
                <span>
                  <Translate stringId="totalActualWater" />
                </span>
                {translateMessage(totalActualWater?.uomCode, { value: totalActualWater.quantity })}
              </>
            ) : (
              translateMessage('totalActualWater')
            )}
          </StyledDiv>
        </StyledBodyMobile>
        <StyledFooter>
          <SignaturesSection />
        </StyledFooter>
      </StyledTable>
    </Styled>
  );
};
