import React, { useMemo } from 'react';
import { Form, Localization } from 'connex-cds';
import { find, isEmpty } from 'lodash';
import { useTicketContext } from '../../../../TicketContext';
import {
  useCompanySetup,
  useReasonCodeSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from '../../../../MasterDataProvider';

const testId = 'activityGrid-returnedMaterial';

export const ReturnedMaterial = () => {
  const { values } = Form.useFormContext();
  const translateMessage = Localization.useTranslateMessage();
  const { ticket } = useTicketContext();

  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();
  const reasonCodeSetup = useReasonCodeSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup?.data, { id: ticket?.vehicleId });
    const targetVehicleType = find(vehicleTypeSetup?.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });
    return targetVehicleType?.id;
  }, [vehicleSetup?.data, vehicleTypeSetup?.data, ticket?.vehicleId]);

  const hasReturnedOptions = useMemo(() => {
    if (!reasonCodeSetup?.data) {
      return [];
    }

    const reasons = reasonCodeSetup?.data?.filter?.(reason => {
      const isReturned = reason?.type === 'RETURNED';
      const isActive = reason?.status === 'ACTIVE';
      const isMulticountry = companySetup?.data?.isMulticountry === true;
      const isNoCountries = isEmpty(reason?.countries?.length);
      const matchCountries = reason?.countries?.includes(ticket?.origin?.address?.countryCode);
      const matchVehicleTypes = reason?.vehicleTypes?.includes(vehicleType);
      const isNoVehicleTypes = isEmpty(reason?.vehicleTypes?.length);

      return (
        isReturned &&
        isActive &&
        (isMulticountry ? matchCountries || isNoCountries : true) &&
        (matchVehicleTypes || isNoVehicleTypes)
      );
    });

    return !isEmpty(reasons);
  }, [companySetup?.data?.isMulticountry, reasonCodeSetup?.data, ticket?.origin?.address?.countryCode, vehicleType]);

  if (!values?.returnReason && !values?.driverDidNotAdd && hasReturnedOptions) {
    return null;
  }

  const translateReason = (returnConcreteOnTruck, returnReason) => {
    if (returnConcreteOnTruck?.value && returnReason) {
      const key = `SETUP_REASON-CODE_${values?.returnReason?.id}`.replace(/\s/gi, '_').toUpperCase();
      const translation = translateMessage(key);

      if (translation && translation !== key) {
        return translation;
      }

      if (returnReason?.label) {
        return returnReason?.label;
      }

      if (returnReason?.description) {
        return returnReason?.description;
      }
    }

    return '';
  };

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>
        <Localization.Translate stringId="returnedMaterial" data-testid="returnedMaterial-label" />
      </td>
      <td></td>
      <td data-testid={`${testId}-concrete-value`}>
        <Localization.Uom
          uom={
            values?.returnConcreteOnTruck?.value
              ? values.returnConcreteOnTruck
              : { value: '0', uomCode: values?.returnConcreteOnTruck?.uomCode }
          }
        />
      </td>
      <td data-testid={`${testId}-reason-value`}>
        {translateReason(values?.returnConcreteOnTruck, values?.returnReason)}
      </td>
    </tr>
  );
};
